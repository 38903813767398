import { useEffect } from "react";
import { useState } from "react"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    const [name, setName] = useState(null)
    const [data, setData] = useState([])

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "tkservicetoken");

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    useEffect(() => {
        fetch("https://popupfunctions.tkevents.io/ETL-POLL-LIVE", requestOptions)
            .then(async result => setData(await result.json()))
            .catch(error => console.log('error', error));
    }, [])

    return <div>
        <div style={{marginBottom: "2em", marginTop: "1em"}}>
            Search
            <input placeholder="Name or Email" onChange={ev => setName(ev.target.value)} />
        </div>

        <table>
            <tr>
                <td>name</td>
                <td>email</td>
                <td>company</td>
                <td>job title</td>
                <td>poll</td>
                <td>giveaway</td>
            </tr>
            {
                data.filter(it => {
                    return !name  || new RegExp(name).test(it?.airpods?.name) || new RegExp(name).test(it?.airpods?.email)
                }).map(it => {
                    return <tr>
                        <td>{it.airpods.name}</td>
                        <td>{it.airpods.email}</td>
                        <td>{it.airpods.company}</td>
                        <td>{it.airpods["job title"]}</td>
                        <td>{it.poll}</td>
                        <td>{it.giveaway}</td>
                    </tr>
                })
            }
        </table>
    </div>
}