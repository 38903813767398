import React, {useEffect, useState} from "react";
import './App.css';
import GiveawayEvent from './pages/giveaway-event';
import PollEvent from './pages/poll-event';
import Thanks from "./pages/thanks";
import Airpods from "./pages/airpods";
import PollResults from "./pages/poll-results";

function App() {
  const [airpods, setairpods] = useState(undefined)
  const [giveaway, setgiveaway] = useState(undefined)
  const [poll, setpoll] = useState(undefined)
  const [page, setpage] = useState(1)
  const [votes, setVotes] = useState({})

  const eventId = "ETL-POLL-LIVE";

  useEffect(() => {
    fetch(`https://popupfunctions.tkevents.io/${eventId}/group-by/poll`, {
      headers: { "Authorization": "tkservicetoken" }
    }).then(async it => {
      setVotes(await it.json())
    })
  }, [])

  useEffect(()=>{
    if (page === 3) setTimeout(()=> {
      setpage(p=>p+1)
    },6000)

    if(page === 5) { setTimeout(()=> {
    
      const data = {
        eventId,
        airpods,
        poll,
        giveaway,
      }
      fetch("https://popupfunctions.tkevents.io/create/" + eventId,{
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      }).finally(()=> {
        window.location.reload()
      })
    }, 5000)
    }
  }, [page])
  
  return <>
{page === 1 && <Airpods onChange={value=>{
      setairpods(value)
      setpage(p=>p+1)}}/>}

{page === 2 && <PollEvent onChange={value=>{
      setVotes(v => ({
        ...v,
        [value]: (v[value] || 0) + 1,
        len: v.len + 1
      }))

      setpoll (value)
      setpage(p=>p+1)}}/>}

{page === 3 && <PollResults data={votes}/>}

{page === 4 && <GiveawayEvent onChange={value=>{
      setgiveaway (value)
      setpage(p=>p+1)
    }}/>}

{page === 5 && <Thanks/>}
  </>
}

export default App;
