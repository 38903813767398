import { useRef } from "react";
import Container from "../components/container"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({onChange})=>{

    const submit = (ev) => {
        ev.preventDefault()
        const data = new FormData(ev.target);
        const value = Object.fromEntries(data.entries());
        onChange(value)
    }

    const formBt = useRef(null)

    return <Container imgurl="/Slide3.png">
        <form onSubmit={submit}>
            <div style={{top: "74.5%", left: "20%", width:"25.1%", height:"5%"}}><input required name="name" placeholder="Name" /></div>
            <div style={{top: "74.5%", left: "53.1%", width:"25.1%", height:"5%"}}><input required name="email" placeholder="Email" type="email"/></div>
            <div style={{top: "86.5%", left: "20%", width:"25.1%", height:"5%"}}><input required name="company" placeholder="Company"/></div>
            <div style={{top: "86.5%", left: "53.1%", width:"25.1%", height:"5%"}}><input required name="job title" placeholder="Job Title"/></div>
            <div onClick={() => {
                formBt.current?.click()
            }} style={{
                right: "10px",
                bottom: "13%",
                width: "8%",
                height: "11%"
            }}><input ref={formBt} style={{display: "none"}} type="submit" value="next"/></div>
            </form>
  </Container>
}