import Container from "../components/container"

export default ({onChange})=>{

    const onClick = (value) => {
        return () => {
        onChange(value)
      }
    }
    
    return <Container imgurl="poll-event.png">
    <div onClick={onClick("in-person")} style={{top: "24%", left: "10%", width:"26%", height:"62%"}}/>
    <div onClick={onClick("virtual")} style={{top: "24%", left: "38%", width:"26%", height:"62%"}}/>
    <div onClick={onClick("hybrid")} style={{top: "24%", left: "67%", width:"26%", height:"62%"}}/>
  </Container>
}
