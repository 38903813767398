import Container from "../components/container"

export default ({data})=>{

    console.log(data)

    const inPerson = data["in-person"] && data["in-person"] > 0 ? data["in-person"]/ data.len * 100 : 0
    const virtual = data["virtual"] && data["virtual"] > 0 ? data["virtual"]/ data.len * 100 : 0
    const hybrid = data["hybrid"] && data["hybrid"] > 0 ? data["hybrid"]/ data.len * 100 : 0

    return <Container imgurl="poll-results.png">
    <div className="percentages" style={{top: "24%", left: "10%", width:"26%", height:"62%"}}>{inPerson.toFixed(0)}%</div>
    <div className="percentages" style={{top: "24%", left: "38%", width:"26%", height:"62%"}}>{virtual.toFixed(0)}%</div>
    <div className="percentages" style={{top: "24%", left: "67%", width:"26%", height:"62%"}}>{hybrid.toFixed(0)}%</div>
  </Container>
}
