import Container from "../components/container"

export default ({onChange})=>{

    const onClick = (value) => {
        return () => {
        onChange(value)
    }
    }

    return <Container imgurl="/poll-thanks.png">
    <div onClick={onClick("Treat")} style={{top: "24%", left: "23%", width:"26%", height:"62%"}}/>
    <div onClick={onClick("Donation")} style={{top: "24%", left: "51%", width:"26%", height:"62%"}}/>
  </Container>
}